import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { api } from '../../api/api';
//hooks
import { useQuery, useQueryClient } from 'react-query';
//pseudotypes
// import { productTypes } from '../../utils/types/productTypes';
//components
import { Button, Dropdown } from 'react-bootstrap';
import { DateWrapper } from '../datepickerWrapper/style';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { DateMask } from '../dateMask/dateMask';
import * as dayjs from 'dayjs';

const ChartFilters = ({
	product,
	channel,
	period,
	dates,
	channelFilter,
	productFilter,
	setChannel,
	setProduct,
	filtersController,
	setFiltersController,
	query,
	order,
	data,
	setData,
	startDateDisplay,
	endDateDisplay,
	setStartDateDisplay,
	setEndDateDisplay,
	setQueryMethods,
	onChange,
}) => {
	const queryClient = useQueryClient();
	const isMounted = useRef(false);
	const [periodFilter, setPeriodFilter] = useState('Personalizado');
	const [customInputStart, setCustomInputStart] = useState('');
	const [customInputEnd, setCustomInputEnd] = useState('');
	const [productTypes, setProductTypes] = useState({});
	const [endDate, setEndDate] = useState(!endDateDisplay ? new Date() : endDateDisplay);
	const [startDate, setStartDate] = useState(
		!startDateDisplay
			? new Date(new Date().setFullYear(new Date().getFullYear() - 1))
			: startDateDisplay
	);

	const { data: productTypesVar, error } = useQuery(`productTypesChar-${Date.now().toString()}`, async () => {
		const response = await api.get('/products');
		setProductTypes(response.data);
	});

	const {
		data: queryData,
		isLoading,
		refetch,
		isFetching,
		isRefetching,
	} = useQuery([`${data}`], {
		queryFn: async ({ signal }) => getData({ signal }),
		onError: (err) => console.log(err),
	});

	const getData = async ({ signal }) => {
		const { filters, date } = handleFilterQuery();
		handleShowDateFiltered();
		const response = await api.get(`${query}?${filters ?? ''}${period ? date : ''}`, { signal });
		console.log('filters');
		setData(response?.data);
	};

	const handleFilterQuery = () => {
		if (filtersController) {
			//convertendo objeto em string params para requisição
			var filters = '';
			for (let index = 0; index < Object.keys(filtersController).length; index++) {
				const obj = Object.entries(filtersController)[index];
				filters = filters.concat(obj.toString().replace(',', '=') + '&');
			}
		}
		const initialDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
			.toString()
			.padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
		const finalDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
			.toString()
			.padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;
		const date = `initialDate=${initialDate}&finalDate=${finalDate}`;

		return { filters, date };
	};
	async function handleShowDateFiltered() {
		if (setStartDateDisplay && setEndDateDisplay) {
			setStartDateDisplay(startDate);
			setEndDateDisplay(endDate);
		}
	}

	const handleChangeProduct = (filter) => {
		const filterArr = Object.entries(filter)[0];
		let filters = filtersController;

		setProduct(productTypes[Object.values(filter)[0]]);

		if (filterArr.includes('all')) {
			setProduct('Todos');
			for (const key in filter) {
				if (Object.hasOwnProperty.call(filter, key) && key === 'product') {
					delete filters[key];
				}
			}
		}
		return filters;
	};

	const handleChangeChannel = (filter) => {
		const filterArr = Object.entries(filter)[0];

		let filters = filtersController;
		setChannel(productTypes[Object.values(filter)[0]]);

		if (filterArr.includes('all')) {
			setChannel('Todos');
			for (const key in filter) {
				if (Object.hasOwnProperty.call(filter, key) && key === 'channel') {
					delete filters[key];
				}
			}
		}
		return filters;
	};

	const handleChangeServicesFilters = (filter) => {
		if (!filter) return;
		let filters = filtersController;
		//adicionando novo filtro a lista de filtros pendente
		filters[Object.keys(filter)[0]] = Object.values(filter)[0];
		const filterArr = Object.entries(filter)[0];

		switch (true) {
			case filterArr.includes('channel'):
				filters = handleChangeChannel(filter);
				break;
			case filterArr.includes('product'):
				filters = handleChangeProduct(filter);
				break;
		}
		setFiltersController(filters);
		handleRefetch();
	};

	const handleChangeDateFilter = () => {
		if (periodFilter === 'Personalizado') return;

		setEndDate(new Date());
		var date = new Date();

		switch (periodFilter) {
			case 'Semanal':
				date.setDate(new Date().getDate() - 7);
				break;
			case 'Mensal':
				date.setMonth(new Date().getMonth() - 1);
				break;
			case 'Anual':
				date.setFullYear(new Date().getFullYear() - 1);
				break;

			default:
				date.setFullYear(new Date().getFullYear() - 1);
				break;
		}
		setStartDate(date);
		handleRefetch();
	};

	const handleCancelRequest = () => {
		queryClient.cancelQueries([`${data}`]);
	};

	function handleRefetch() {
		if (period) handleShowDateFiltered();
		handleCancelRequest();
		setTimeout(() => refetch(), 100);
		if (onChange) onChange();
	}
	// [channelFilter, productFilter, startDate, endDate]);

	const changePeriodFilter = (filter) => {
		setPeriodFilter(filter);
		if (typeof filter !== 'number') {
			handleChangeDateFilter();
			return;
		}
		setStartDate(new Date(`${filter}-01-02`));
		setEndDate(new Date(`${filter}-12-31`));
		handleRefetch();
	};

	useEffect(() => {
		setQueryMethods({ isLoading, refetch, isFetching, isRefetching });
	}, [isLoading, refetch, isFetching, isRefetching]);

	useEffect(() => {
		if (!customInputStart || !data) return;

		const regexDate = /\d{2}\/\d{2}\/\d{4}/;
		if (regexDate.test(customInputStart)) {
			const date = dayjs(
				`${customInputStart.split('/')[2]}-${customInputStart.split('/')[1]}-${Number(
					customInputStart.split('/')[0]
				)}`
			);
			if (dayjs(startDate).startOf('day').toISOString() !== date.toISOString()) {
				setStartDate(new Date(date.toISOString()));
				handleRefetch();
			}
		}
	}, [customInputStart]);

	useEffect(() => {
		console.log(customInputEnd);
		if (!customInputEnd || !data) return;

		const regexDate = /\d{2}\/\d{2}\/\d{4}/;
		if (regexDate.test(customInputEnd)) {
			const date = dayjs(
				`${customInputEnd.split('/')[2]}-${customInputEnd.split('/')[1]}-${Number(
					customInputEnd.split('/')[0]
				)}`
			);

			if (dayjs(endDate).startOf('day').toISOString() !== date.toISOString()) {
				setEndDate(new Date(date.toISOString()));
				handleRefetch();
			}
		}
	}, [customInputEnd]);

	return (
		<div className='d-flex align-items-center flex-wrap' style={{ gap: '1rem' }}>
			{order ? (
				order.map((filter) => {
					if (filter === 'channel')
						return (
							<Dropdown>
								<b>Canal: </b>
								<Dropdown.Toggle
									as={Button}
									variant='text-gray'
									type='button'
									id='dropdownMenuButtonSM'
								>
									{channelFilter}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => handleChangeServicesFilters({ channel: 'all' })}>
										Todos
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => (
											handleChangeServicesFilters({ channel: 'os' }), setChannel('OS')
										)}
									>
										OS
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => (
											handleChangeServicesFilters({ channel: 'chat' }), setChannel('Chat')
										)}
									>
										Chat
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => (
											handleChangeServicesFilters({ channel: 'ligacao' }), setChannel('Ligação')
										)}
									>
										Ligação
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						);
					if (filter === 'product')
						return (
							<Dropdown>
								<b>Produto: </b>
								<Dropdown.Toggle as={Button} variant='text-gray'>
									{productFilter}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => handleChangeServicesFilters({ product: 'all' })}>
										Todos
									</Dropdown.Item>
									{Object.keys(productTypes).map((productKey) => (
										<Dropdown.Item
											onClick={() => handleChangeServicesFilters({ product: productKey })}
										>
											{productTypes[productKey]}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						);
					if (filter === 'period')
						return (
							<>
								<Dropdown>
									<b>Período: </b>

									{dates ? (
										<>
											<Dropdown.Toggle
												as={Button}
												variant='text-gray'
												type='button'
												id='dropdownMenuButtonSM'
											>
												{periodFilter}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={() => changePeriodFilter('Semanal')}>
													Semanal
												</Dropdown.Item>
												<Dropdown.Item onClick={() => changePeriodFilter('Mensal')}>
													Mensal
												</Dropdown.Item>
												<Dropdown.Item onClick={() => changePeriodFilter('Anual')}>
													Anual
												</Dropdown.Item>
												<Dropdown.Item onClick={() => changePeriodFilter('Personalizado')}>
													Personalizado
												</Dropdown.Item>

												<Dropdown.Divider />

												{dates
													?.sort((a, b) => b - a)
													?.map((year) => (
														<Dropdown.Item onClick={() => changePeriodFilter(year)}>
															{year}
														</Dropdown.Item>
													))}
												<Dropdown.Item onClick={() => changePeriodFilter('Personalizado')}>
													Personalizado
												</Dropdown.Item>
											</Dropdown.Menu>
										</>
									) : null}
								</Dropdown>
								<DateWrapper>
									<DatePicker
										locale='pt-BR'
										dateFormat='dd/MM/yyyy'
										placeholderText='Data inicial'
										selectsEnd
										selected={startDate}
										startDate={startDate}
										endDate={endDate}
										onSelect={(update) => {
											setCustomInputEnd(
												setCustomInputStart(
													`${dayjs(update).date().toString().padStart(2, '0')}/${(
														dayjs(update).month() + 1
													)
														.toString()
														.padStart(2, '0')}/${dayjs(update).year()}`
												)
											);
										}}
										onChange={(update) => {
											changePeriodFilter('Personalizado');
										}}
										customInput={<DateMask setDisplayValue={setCustomInputStart} />}
										showMonthDropdown
									/>
								</DateWrapper>
								até
								<DateWrapper>
									<DatePicker
										locale='pt-BR'
										dateFormat='dd/MM/yyyy'
										placeholderText='Data final'
										selectsStart
										selected={endDate}
										startDate={startDate}
										endDate={endDate}
										minDate={startDate}
										onSelect={(update) => {
											setCustomInputEnd(
												`${dayjs(update).date().toString().padStart(2, '0')}/${(
													dayjs(update).month() + 1
												)
													.toString()
													.padStart(2, '0')}/${dayjs(update).year()}`
											);
										}}
										onChange={(update) => {
											changePeriodFilter('Personalizado');
										}}
										customInput={<DateMask setDisplayValue={setCustomInputEnd} />}
										showMonthDropdown
									/>
								</DateWrapper>
							</>
						);
				})
			) : (
				<>
					{period && (
						<>
							<Dropdown>
								<b>Período: </b>

								{dates ? (
									<>
										<Dropdown.Toggle
											as={Button}
											variant='text-gray'
											type='button'
											id='dropdownMenuButtonSM'
										>
											{periodFilter}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={() => changePeriodFilter('Semanal')}>
												Semanal
											</Dropdown.Item>
											<Dropdown.Item onClick={() => changePeriodFilter('Mensal')}>
												Mensal
											</Dropdown.Item>
											<Dropdown.Item onClick={() => changePeriodFilter('Anual')}>
												Anual
											</Dropdown.Item>
											<Dropdown.Item onClick={() => changePeriodFilter('Personalizado')}>
												Personalizado
											</Dropdown.Item>

											<Dropdown.Divider />

											{dates
												?.sort((a, b) => b - a)
												?.map((year) => (
													<Dropdown.Item onClick={() => changePeriodFilter(year)}>
														{year}
													</Dropdown.Item>
												))}
											<Dropdown.Item onClick={() => changePeriodFilter('Personalizado')}>
												Personalizado
											</Dropdown.Item>
										</Dropdown.Menu>
									</>
								) : null}
							</Dropdown>
							<DateWrapper>
								<DatePicker
									locale='pt-BR'
									dateFormat='dd/MM/yyyy'
									placeholderText='Data inicial'
									selectsEnd
									selected={startDate}
									startDate={startDate}
									endDate={endDate}
									onSelect={(update) => {
										setCustomInputStart(
											`${dayjs(update).date().toString().padStart(2, '0')}/${(
												dayjs(update).month() + 1
											)
												.toString()
												.padStart(2, '0')}/${dayjs(update).year()}`
										);
									}}
									onChange={(update) => {
										changePeriodFilter('Personalizado');
									}}
									customInput={<DateMask setDisplayValue={setCustomInputStart} />}
									showMonthDropdown
								/>
							</DateWrapper>
							até
							<DateWrapper>
								<DatePicker
									locale='pt-BR'
									dateFormat='dd/MM/yyyy'
									placeholderText='Data final'
									selectsStart
									selected={endDate}
									startDate={startDate}
									endDate={endDate}
									minDate={startDate}
									onSelect={(update) => {
										setCustomInputEnd(
											`${dayjs(update).date().toString().padStart(2, '0')}/${(
												dayjs(update).month() + 1
											)
												.toString()
												.padStart(2, '0')}/${dayjs(update).year()}`
										);
									}}
									onChange={(update) => {
										changePeriodFilter('Personalizado');
									}}
									customInput={<DateMask setDisplayValue={setCustomInputEnd} />}
									showMonthDropdown
								/>
							</DateWrapper>
						</>
					)}

					{(
						<Dropdown>
							<b>Produto: </b>
							<Dropdown.Toggle as={Button} variant='text-gray'>
								{productFilter}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => handleChangeServicesFilters({ product: 'all' })}>
									Todos
								</Dropdown.Item>
								{Object.keys(productTypes).map((productKey) => (
									<Dropdown.Item
										onClick={() => handleChangeServicesFilters({ product: productKey })}
									>
										{productTypes[productKey]}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					)}

					{channel && (
						<Dropdown>
							<b>Canal: </b>
							<Dropdown.Toggle
								as={Button}
								variant='text-gray'
								type='button'
								id='dropdownMenuButtonSM'
							>
								{channelFilter}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => handleChangeServicesFilters({ channel: 'all' })}>
									Todos
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => (handleChangeServicesFilters({ channel: 'os' }), setChannel('OS'))}
								>
									OS
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => (
										handleChangeServicesFilters({ channel: 'chat' }), setChannel('Chat')
									)}
								>
									Chat
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => (
										handleChangeServicesFilters({ channel: 'ligacao' }), setChannel('Ligação')
									)}
								>
									Ligação
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					)}
				</>
			)}
		</div>
	);
};

export default ChartFilters;
